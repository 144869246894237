.head_page {
    display: flex;
    justify-content: space-around;
    cursor: pointer;
    height: 35px;
    background-color: #ffffff;
}

.head_page_text {
    width: 105px;
    height: 29px;
    font-size: 14px;
    font-family: HarmonyOS_Sans_SC;
    color: #000000;
    line-height: 35px;
}

.img_page {
    display: flex;
    background-color: #F7F7F7;
    flex-wrap: wrap;
}

.img_page_index {
    padding: 0px 20%;
    flex: 1 1 auto;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

/* 浏览器窗口宽度小于700px时的样式 */
@media screen and (max-width:990px) {
    .img_page_index {
        padding: 0px 10%;   
    }
} 

.lnk_screen_deltails {
    display: flex;
    flex-direction: column;
    margin: 40px 15% 311px 15%;
}

.lnk_screen_deltails_title {
    height: 36px;
    font-size: 26px;
    font-family: HarmonyOS_Sans_SC_Medium;
    color: #333333;
    line-height: 36px;
}

.lnk_screen_deltails_context {
    display: flex;
    flex-wrap: wrap;
}

.lnk_screen_deltails_context_model {
    margin-bottom: 30px;
    margin-top: 30px;
    padding-right: 30px;
    width: 280px;
}

.lnk_screen_deltails_context_title {
    height: 19px;
    font-size: 14px;
    font-family: HarmonyOS_Sans_SC;
    color: #666666;
    line-height: 19px;
}

.lnk_screen_deltails_context_context {
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC_Medium;
    color: #333333;
    line-height: 23px;
}

.lnk_screen_deltails_context_explain {
    height: 17px;
    font-size: 12px;
    font-family: HarmonyOS_Sans_SC;
    color: #999999;
    line-height: 17px;
}