.serve_maintain_content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 100px 15% 149px 15%;
    background-image: url(https://officialwebsite.s3.cn-south-1.jdcloud-oss.com/UnderstandRealsureBackgroup.png);
    background-size: cover;
    background-position: center center;
}

.serve_maintain_guanyubanner{
    width: 100%;
}

.serve_maintain_title{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.serve_maintain_title_huinuo{
    font-size: 35px;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #333333;
    line-height: 79px;
    z-index: 999;
    font-weight: 600;
}

.serve_maintain_content_title{
    color: #333333;
    font-size: 20px;
    font-family: HarmonyOS_Sans_SC_Medium;
}

.serve_maintain_content_content{
    font-size: 20px;
    font-family: HarmonyOS_Sans_SC;
    color: #666666;
    line-height: 42px;
}