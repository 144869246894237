.operate_fingerpost_content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 100px 15% 149px 15%;
    background-size: cover;
    background-position: center center;
    background-color: #f7f7f7;
}

.operate_fingerpost_guanyubanner {
    width: 100%;
}

.operate_fingerpost_title {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.operate_fingerpost_title_huinuo {
    font-size: 35px;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #333333;
    line-height: 79px;
    z-index: 999;
    font-weight: 600;
}

.operate_fingerpost_content_title {
    color: #333333;
    font-size: 20px;
    font-family: HarmonyOS_Sans_SC_Medium;
}

.operate_fingerpost_content_content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.operate_fingerpost_content_content_div {
    margin: 5px;
    width: 300px;
    height: 300px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.operate_fingerpost_content_content_font {
    font-size: 18px;
    font-family: HarmonyOS_Sans_SC;
    color: #666666;
    line-height: 25px;
}