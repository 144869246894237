.home_header {
    /* float: left; */
    /* height: 60px; */
    /* margin-left: 40vw; */
    /* display: flex; */
    height: 60px;
    margin-left: 300px;
}

.home_menu {
    height: 60px;
    background-color: #000000;
}

.home_menu_parend {
    /* min-width: 600px; */
    margin-right: 100px;
}

.home_menu_parend .ant-menu-title-content {
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    color: #DCDDDD;
}

.home_language {
    transform: scale(0.8);
    margin-top: 15px;
}

.home_titlepng {
    /* position: absolute;
    top: 18px;
    left: 10%;
    height: 26px; */
    height: 26px;
    float: left;
    margin: 15px 0 0 50px;
}

.login_button {
    /* font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    color: #DCDDDD;
    margin-left: 60px;
    cursor: pointer; */
    font-size: 16px;
    font-family: HarmonyOS_Sans_SC;
    color: #DCDDDD;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 2%;
}

.home_fenge {
    display: flex;
    flex-direction: column;
    background-color: rgba(216, 216, 216, 1);
    width: 1px;
    height: 17px;
    margin: 25px 20px 0 20px;
}

.home_footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.home_footer_content {
    display: flex;
    justify-content: center;
}

.home_footer_bottom_right {
    display: flex;
    justify-content: space-evenly;
    float: right;
    width: 40%;
}

.home_footer_header {
    display: flex;
    justify-content: space-evenly;
    float: left;
    width: 60%;
}

.home_down_company {
    border-top: 1px solid white;
    height: 40px;
    line-height: 60px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_down_company_phone{
    border-top: 1px solid white;
    color: white;
    display: none;
    flex-direction: column;
    margin: 0px 0 10px 0;
    padding: 5px 0 0 0;
}

.home_down_zhu {
    height: 25px;
    font-size: 18px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #FFFFFF;
    text-align: left;
    margin: 30px 0px;
}

.home_down_fu {
    font-size: 14px;
    font-weight: 500;
    color: #DCDDDD;
    line-height: 37px;
    text-align: left;
    cursor: pointer;
}

.ant-layout {
    background: #ffffff !important;
}

.huinuowenzi {
    color: #DCDDDD;
}

.home_footer_bottom_right_erweima {
    margin: 51px 15px 0 15px;
}

.popover_tanchu {
    display: flex;
}

.popover_tanchu_101 {
    cursor: pointer;
    margin: 20px 25px 0px 38px;
}

.popover_tanchu_103 {
    cursor: pointer;
    margin: 20px 38px 0px 25px;
}

.popover_tanchu_yinpin {
    cursor: pointer;
    margin: 20px 40px 0px 40px;
}

.popover_tanchu_text {
    margin-bottom: 20px;
    text-align: center;
}

.ant-menu-item-only-child:hover {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 100%) !important;
}

::after {
    border-bottom: "";
    border-bottom-color: "";
    border-bottom-width: 1px !important;
}

.popover_caozuozhinan_text {
    width: 70px;
    height: 19px;
    font-size: 14px;
    font-family: HarmonyOS_Sans_SC_Medium;
    color: #000000;
    line-height: 19px;
}

.hover_div_pictrue {
    cursor: pointer;
    margin: 15px 25px 0px 25px;
}

.hover_div_font {
    cursor: pointer;
    margin: 15px 9px 3px 7px;
}

:where(*).ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
    border-bottom-color: transparent !important;
}

:where(*).ant-menu-light.ant-menu-horizontal>.ant-menu-item-active:hover:after {
    border-bottom-color: transparent !important;
}

.ant-menu-submenu-title {
    color: white;
}

.ant-menu-submenu-title:hover {
    color: white !important;
}

.overlay {  
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0);
    display: none;
    z-index: 9999;
    flex-direction: column;
}

.overlay_home_titlepng{
    height: 20px;
    float: left;
    margin: 0px 0 0 40px;
    position: absolute;
    top: 30px;
    left: 0px;
}
  
.dialog {  
    height: 100%;
}  

.overlay_return{
    color: #ffffff;
    font-size: 30px;
    padding-left: 30px;
}

.overlay_home_menu{
    color: #ffffff;
    padding-left: 40px;
    /* margin-top: 75px; */
    font-size: 20px;
    height: 100%;
    background-color: #1c1c1c;
}
  
.close_btn {  
    position: absolute;  
    top: 15px;  
    right: 15px;  
    cursor: pointer;  
    transform: scale(0.4);
}

.gengduo_btn {  
    position: absolute;
    top: 9px;
    right: 0%;
    cursor: pointer;
    transform: scale(0.4);
    display: none;
}

.home_erweima{
    width: 110px;
}

.home_down_lianxifangshi{
    text-align-last: start;
    margin: 30px 0 30px 62%;
    color: white;
    font-family: HarmonyOS_Sans_SC;
}

.home_down_lianxifangshi_up{
    margin-bottom: 10px;
}


/* 浏览器窗口宽度小于700px时的样式 */
@media screen and (max-width:990px) {
    .home_footer_header {
        display: none;
    }
    
    .home_menu_parend {
        display: none;
    }

    .login_button {
        display: none;
    }

    .home_down_company_phone{
        display: flex;
    }

    .home_down_company {
        display: none;
    }

    .gengduo_btn{
        display: block;
    }

    .ant-menu-submenu-arrow{
        color: #000000 !important;
    }

    .ant-menu-title-content{
        color: #000000;
    }

} 

