.business_cooperation_content{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 100px 0 149px 0;
}
.business_cooperation_content_left{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 15%;
}
.business_cooperation_content_right{
    width: 50%;
    margin-right: 15%;
}

.business_cooperation_guanyubanner{
    width: 100%;
}

.business_cooperation_content_left_title{
    font-size: 22px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
}

.business_cooperation_content_left_content{
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    margin-top: 30px;
}