.understand_realsure_content{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 100px 15% 149px 15%;
    background-image: url(https://officialwebsite.s3.cn-south-1.jdcloud-oss.com/UnderstandRealsureBackgroup.png);
    background-size: cover;
    background-position: center center;
}

.understand_realsure_guanyubanner{
    width: 100%;
}

.understand_realsure_title{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 70px;
}

.understand_realsure_title_huinuo{
    width: 112px;
    height: 79px;
    font-size: 56px;
    font-family: HarmonyOS_Sans_SC_Bold;
    color: #333333;
    line-height: 79px;
    z-index: 999;
    font-weight: 600;
}

.understand_realsure_title_real{
    position: absolute;
    top: 35px;
    height: 70px;
    width: 413px;
    background-image: url(https://officialwebsite.s3.cn-south-1.jdcloud-oss.com/realsureBackgroup.png);
}

.understand_realsure_content_title{
    color: #333333;
    font-size: 20px;
    font-family: HarmonyOS_Sans_SC_Medium;
    margin-bottom: 20px;
    font-weight: bolder;
}

.understand_realsure_content_content{
    font-size: 20px;
    font-family: HarmonyOS_Sans_SC;
    color: #666666;
    line-height: 42px;
    margin-bottom: 20px;
}