.homepage_img {
    width: 100%;
    cursor: pointer;
}

.homepage_img_48 {
    width: 48%;
}

.homepage_img_heng_2 {
    margin-top: 61px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.homepage_center_img {
    display: flex;
    flex-direction: column;
}

.homepage_news_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: #F7F7F7;
    margin-top: 20px;
}

.homepage_news_list_title {
    font-size: 34px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    line-height: 48px;
    margin-top: 20px;
}

.homepage_news_list_content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 50px;
}

.homepage_news_list_content_neirong {
    position: relative;
    top: -40px;
    background-color: white;
    margin: 0 20px;
}

.homepage_news_list_content_picture {
    width: 100%;
}

.homepage_news_list_content_one {
    width: 30%;
    margin-right: 21px;
    cursor: pointer;
}

.homepage_news_list_content_two {
    width: 30%;
    margin: 0 21px;
    cursor: pointer;
}

.homepage_news_list_content_three {
    width: 30%;
    margin-left: 21px;
    cursor: pointer;
}

.homepage_news_list_content_gengduo_news {
    text-align: right;
    margin-right: 32px;
    margin-bottom: 30px;
    font-size: 1px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    cursor: pointer;
}

.homepage_news_list_content_shijian {
    display: flex;
    justify-content: space-between;
}

.homepage_news_list_content_title {
    width: 85%;
    margin: 0 0 0 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
}

.homepage_news_list_content_button {
    width: 70px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #999999;
    line-height: 17px;
    margin: 0 10px 5px 10px;
}

.homepage_news_list_content_one_abstract{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 5px 35px;
}